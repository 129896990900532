<template>
  <div>
    <div ref="theChart" className="echarts1">{{ data }}</div>
  </div>
</template>
<script>
import echarts from 'echarts';
import {round} from "@/utils/format";


export default {
  name: 'ThreeChart',
  data() {
    return {
      chartColumnM: null,
      chartData: [],
    };
  },
  props: {
    data: Object,
    width: String,
    height: String,
  },
  computed: {
    unit() {
      return this.data.unit || '万元';
    },
    headers() {
      return this.data.series.map((value) => value.name);
    },
    series() {
      const ret = [];

      const series = [

        {type: 'line', color: '#D6B35B', barWidth: '30'},
        {type: 'bar', color: '#597EF7', barWidth: '30'},
        //{type: 'bar', color: '#ADC6FF', barWidth: '30'},
      ];

      for (let i = 0; i < this.headers.length; i++) {
        const aSeries = series[i];
        aSeries.name = this.headers[i];


        // const max = this.data.series[i].data.map(v=>v*1.01);
        // const min = this.data.series[i].data.map(v=>v*0.09);
        // const placeholder = max.map((v,j)=>{
        //   return v-min[j]
        // })-min;
        // const incre = this.data.series[i].data.map(v=>v*0.02);
        // debugger

        aSeries.data = this.data.series[i].data;
        ret.push(aSeries);
      }
      let lastSeries = ret.pop()
      const min = lastSeries.data.map(v => v * 0.99);
      ret.push({
        name: 'Placeholder',
        type: 'bar',
        stack: 'Total',
        itemStyle: {
          borderColor: 'transparent',
          color: 'transparent'
        },
        emphasis: {
          itemStyle: {
            borderColor: 'transparent',
            color: 'transparent'
          }
        }, data: min
      },)

      lastSeries.data = lastSeries.data.map(v => v * 0.02);
      lastSeries.stack = 'Total'

      ret.push(lastSeries)

      return ret;
    },

  },
  mounted() {
    if (this.width) {
      this.$refs.theChart.style.width = this.width;
    }

    if (this.height) {
      this.$refs.theChart.style.height = this.height;
    }

    const options = {
      legend: {
        data: this.headers,
        x: 'center',
        bottom: '5%',
      },
      tooltip: {
        trigger: 'axis',
        position: 'bottom',
        axisPointer: {type: 'shadow'},
        formatter: (params) => {
          let text = "";
            text += this.data.series[0].name + ':' + this.data.series[0].data[params[0].dataIndex] + this.unit + '<br/>';


            const value = this.data.series[1].data[params[0].dataIndex];
            const lowValue = round(value * (1 - 0.01), 2);
            const upValue = round(value * (1 + 0.01), 2);
            const text2 = this.data.series[1].name + ':' + lowValue + this.unit + '~' + upValue + this.unit;
            text += text2;


          return text;
        },
      },
      // dataset: {
      //   dimensions: ['product', ...this.headers],
      //   source: this.source,
      // },

      xAxis: {
        type: 'category',
        splitLine: {
          show: true,
          lineStyle: {
            color: '#EEEEEE',
            width: 1,
            type: 'solid',
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#666',
          },
        },
        axisLabel: {
          // rotate: 35,
          interval: 0,
          textStyle: {
            fontSize: 11,
            fontFamily: 'PingFangSC-Regular,PingFang SC',
            color: 'rgba(0,0,0,0.6)',
          },
        },
        data: this.data.xLabels
      },
      grid: {
        left: '3%',
        right: '5%',
        top: '10%',
        bottom: '20%',
        containLabel: true,
      },
      yAxis: {
        type: 'value',
        axisLabel: {formatter: `{value} ${this.unit}`},
        axisLine: {
          show: true,
          lineStyle: {
            color: '#666',
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#EEEEEE',
            width: 1,
            type: 'dashed',
          },
        },
      },
      // Declare several bar series, each will be mapped
      // to a column of dataset.source by default.
      series: this.series,
    };
    const myChart = echarts.init(this.$refs.theChart);
    myChart.setOption(options);
  },
};
</script>
<style lang="stylus" scoped>
.echarts1 {
  width: 533px;
  height: 300px;

  >>> div:first-child {
    width: 100% !important;
  }
}
</style>
