<template>
  <div v-loading="!loaded" style="min-height: 556px">
    <div class="toolbar" >
      <el-radio-group v-model="activeIndex">
        <el-radio-button label="上游客户"></el-radio-button>
        <el-radio-button label="下游客户"></el-radio-button>
      </el-radio-group>

    </div>
    <div v-if="activeIndex=='上游客户'">
      <ChinaCityTable :data="data" :type="'2'" :key="2"></ChinaCityTable>
    </div>
    <div v-if="activeIndex=='下游客户'">
      <ChinaCityTable :data="data" :type="'1'" :key="1"></ChinaCityTable>
    </div>
  </div>
</template>

<script>
import ChinaCityTable from "@/pages/Result/ThreeYearResult/financialAnalysis/ChinaCityTable";
import {getCompanyAreaList} from "@/api/threeYears";

export default {
  name: "UpDownCustomerDistribution",
  components: {
    ChinaCityTable
  },
  data() {
    return {
      activeIndex: "上游客户",
      upStreamfilter: {
        time: [],
        limit: 15
      },
      data:null,
      loaded:false
    };
  },
  async mounted() {
    this.loaded=false
    await this.loadCompanyAreaList();
    this.loaded=true
  },
  methods:{
    async loadCompanyAreaList(){
      const res = await getCompanyAreaList({
        programId:this.$route.params.uuid
      });
      this.data = res.data
    }
  }
}
</script>

<style lang="stylus" scoped>
.toolbar
  margin-top 24px
  display flex
  justify-content space-between
  align-items center



>>> .el-radio-button {
  margin-right 10px

  .el-radio-button__inner {
    border-radius: 2px;
    padding 6px 16px
    border 0 none;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #86909C;
    box-shadow none;
  }
}

>>> .el-radio-button.is-active {
  .el-radio-button__inner {
    background: rgba(22, 93, 255, 0.1);
    color: #165DFF;
    font-weight bold
  }
}
</style>
