<template>
  <div ref="theChart" class="echarts1"></div>
</template>
<script>
import echarts from "echarts";

export default {
  props: {
    chartData: Object
  },
  data() {
    return {
      colors: [
        "#E86452",
        "#F6BD16",
        "#5AD8A6",
        "#6293F8",
        "#1D73C4",
        "#541DC4",
        "#599EF7",
        "#59F78C",
        "#F7A659",
        "#F75959",
        "#000000",
      ],
    };
  },
  computed: {
    source() {
      const ret = [['product', ...this.chartData.xData]]
      this.chartData.yData.forEach(v => {
        ret.push([v.name, ...v.yData])
      })
      return ret;
    }
  },
  mounted() {
    const myChart = echarts.init(this.$refs.theChart);
    const options = {
      legend: {
        x: "right"
      },
      tooltip: {
        trigger: "axis",
        formatter(params) {
          let str = params[0].name+'<br/>';
          for (const param of params) {
            str += `${param.dimensionNames[param.seriesIndex + 1]}:${param.data[param.seriesIndex + 1]}万元<br/>`
          }
          return str;
        }
      },
      dataset: {
        source: this.source
      },
      xAxis: [
        {
          type: 'category', gridIndex: 0,
          boundaryGap: false,
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEEEEE",
              width: 1,
              type: "solid",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#666",
            },
          },

          axisTick: {
            alignWithLabel: true
          }
        },


      ],
      yAxis: {
        type: "value",
        axisLabel: {formatter: '{value} 万元'},
        axisLine: {
          show: true,
          lineStyle: {
            color: "#666",
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#EEEEEE",
            width: 1,
            type: "dashed",
          },
        },

      },
      grid: {
        left: "3%",
        right: "5%",
        top: "20%",
        bottom: "15%",
        containLabel: true,
      },
      dataZoom: [
        {
          show: true,
          xAxisIndex: [0],
          type: 'slider',
          top: '90%',
          start: 0,
          end: 100
        }
      ],
      series: [
        // These series are in the first grid.
        {
          type: 'line', seriesLayoutBy: 'row', color: this.colors[3], barWidth: "30%"
          , label: {
            normal: {

              show: false,
              position: 'top',
              textStyle: {
                color: '#333',
              }
            }
          },
        },
        {
          type: 'line', seriesLayoutBy: 'row', color: this.colors[2], barWidth: "30%",

          label: {
            normal: {
              show: false,
              position: 'top',
              textStyle: {
                color: '#333'
              }
            }
          },
        },
      ]
    };


    myChart.setOption(options);

    // myChart.on('mouseover', function (params) {// 鼠标移入
    //
    //   const option = myChart.getOption();
    //   const series = option.series
    //   series.forEach(aSeries => {
    //     aSeries.label.show = false
    //     aSeries.lineStyle.opacity = 0.2
    //   })
    //   myChart.setOption({tooltip: {show:false},series})
    //
    //   myChart.setOption({// 设置 鼠标移入后想要的样式
    //     series: {
    //       name: params.seriesName,
    //       lineStyle: {
    //         opacity: 1,
    //         width: 4
    //       },
    //       symbolSize: 4,
    //       label: {
    //         normal: {
    //           show: true,
    //           textStyle: {
    //             color: '#333'
    //           }
    //         }
    //       },
    //     }
    //   })
    // })
    // myChart.on('mouseout', function (params) {// 鼠标移出
    //
    //
    //   const option = myChart.getOption();
    //   const series = option.series
    //   series.forEach(aSeries => {
    //     aSeries.label.show = true
    //     aSeries.lineStyle.opacity = 1
    //   })
    //   myChart.setOption({tooltip: {show:true},series})
    //
    //   myChart.setOption({// 将样式复原
    //     series: {
    //       name: params.seriesName,
    //       symbolSize: 2,
    //       lineStyle: {
    //         width: 2
    //       }
    //     }
    //   })
    // })

  },
};
</script>
<style lang="stylus" scoped>
.echarts1 {

  >>> div:first-child {
    width: 100% !important;
  }
}

.inOutCompare {

}
</style>
