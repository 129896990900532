<template>
  <el-dialog
    id="status_upload"
    title="报表确认"
    :visible.sync="visible"
    width="772"
    v-if="uploadMessage"
  >
    <!-- <el-tabs class="headercss" type="card"> -->
    <!-- <el-tab-pane
        :label="item.zqZw"
        v-for="(item, key) in uploadList.data"
        :key="key"
      >
        <el-tabs class="status_upload1">
          <el-tab-pane
            :label="index + '(' + item1.number + '/' + item1.length + ')'"
            v-if="typeof item1 == 'object'"
            v-for="(item1, index) in item"
            :key="index"
          >
            <div class="table-container">
              <table class="table">
                <tr>
                  <th v-if="index == '所得税表'">表单编号</th>
                  <th>表单名称</th>
                  <th>状态</th>
                </tr>
                <tr v-for="(v, index1) in item1" :key="index1">
                  <td v-if="index == '所得税表'">{{ v.tableNum }}</td>
                  <td>{{ v.tableNameCn }}</td>
                  <td v-if="v.uploadStatus == true" class="statusup statusup1">
                    已上传
                  </td>
                  <td v-else class="statusup">未上传</td>
                </tr>
              </table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane> -->
    <el-tabs v-model="activeName" @tab-click="handleClick" class="headercss" type="card">
      <el-tab-pane :label="zqNameValue(zqs[0])" name="first">
        <el-tabs class="status_upload1">
          <template v-if="uploadMessage">
            <el-tab-pane
              :label="item.name + '(' + item.uploadNum + '/' + item.totalNum + ')'"
              v-for="item in uploadMessage"
              :key="item.name"
            >
              <el-table
                :data="item.tableInfo"
                style="width: 100%"
                height="400"
                row-key="tableNameCn"
                :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              >
                <el-table-column prop="tableNameCn" label="表单名称"> </el-table-column>
                <el-table-column prop="uploadStatus" label="状态" width="100px">
                  <template slot-scope="scope">
                    <span v-if="scope.row.uploadStatus == '已上传'" class="done"></span>
                    <span
                      v-if="scope.row.uploadStatus == '未上传'"
                      class="pedding"
                    ></span>
                    <span>{{ scope.row.uploadStatus }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </template>
        </el-tabs>
      </el-tab-pane>
      <el-tab-pane :label="zqNameValue(zqs[1])" name="second">
        <el-tabs class="status_upload1">
          <template v-if="uploadMessage">
            <el-tab-pane
              :label="item.name + '(' + item.uploadNum + '/' + item.totalNum + ')'"
              v-for="item in uploadMessage"
              :key="item.name"
              ><el-table
                :data="item.tableInfo"
                style="width: 100%"
                height="400"
                row-key="tableNameCn"
                :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              >
                <el-table-column prop="tableNameCn" label="表单名称"></el-table-column>
                <el-table-column prop="uploadStatus" label="状态" width="100px">
                  <template slot-scope="scope">
                    <span v-if="scope.row.uploadStatus == '已上传'" class="done"></span>
                    <span v-else class="pedding"></span>
                    <span>{{ scope.row.uploadStatus }}</span>
                  </template>
                </el-table-column>
              </el-table></el-tab-pane
            >
          </template>
        </el-tabs></el-tab-pane
      >
    </el-tabs>

    <span slot="footer" class="dialog-footer">
      <div class="redfont">*请仔细检查您上传的报表是否完整</div>
      <el-button
        type="primary"
        class="btnGenerate"
        @click="handleBtnStartClick"
        v-loading="loading1"
        >生成测评报告</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { confirmUploadedTable } from "@/api/api";
import { getUserTables } from "@/api/table";

import { mapState } from "vuex";

export default {
  data: function () {
    return {
      visible: false,
      newuploadList: [],
      uploadList: [],
      loading1: false,
      zqAct: 0,
      uploadMessage: null,
      activeName: "first",
    };
  },
  props: {
    bankId: [String],
    zqs: Array,
    productID: [String, Number],
    uuid: [String],
  },
  computed: {
    ...mapState(["companyInfo"]),
  },
  async mounted() {
    //企业信息
    await this.$store.cache.dispatch("loadCompanyInfo");
  },
  methods: {
    zqNameValue(zq) {
      let zqLast = zq.substr(zq.length - 1, 1);

      if (zqLast == 1) {
        return zq.slice(0, 4) + "年" + zq.slice(4, 6) + "月";
      }

      if (zqLast == 4) {
        return zq.slice(0, 4) + "年";
      }
    },
    handleClick(tab) {
      this.genUploadList(tab.index);
    },
    async genUploadList(index) {
      //上传的表格信息
      const tables = await getUserTables(
        this.bankId,
        this.$route.params.version,
        this.companyInfo.nsrsbm,
        this.zqs[index],
        this.uuid,
        false
      );
      this.uploadMessage = tables.data;
      let tabName = [];
      for (var key in this.uploadMessage) {
        this.uploadMessage[key].name = key;
      }
      // let zqload = [];
      // zqload[0] =
      // this.uploadMessage = [
      //   tables.data["所得税表"],
      //   tables.data["财务表"],
      //   tables.data["增值税表"],
      // ];
      // [
      //   this.uploadMessage[0].name,
      //   this.uploadMessage[1].name,
      //   this.uploadMessage[2].name,
      // ] = ["所得税表", "财务表", "增值税表"];
      // for (let i = 0; i < this.uploadMessage.length; i++) {
      //   let successArr = this.uploadMessage[i].tableInfo.filter((item) => {
      //     return item.uploadStatus == "未上传";
      //   });
      //   let errArr = this.uploadMessage[i].tableInfo.filter((item) => {
      //     return item.uploadStatus == "已上传";
      //   });
      //   this.uploadMessage[i].tableInfo = [].concat(errArr, successArr);
      // }
      // const params = {
      //   bankId: this.bankId,
      //   qykjzd: this.companyInfo.kjzd,
      //   nsrsbh: this.companyInfo.nsrsbm,
      //   zqList: this.zqs,
      //   goodsId: this.productID,
      // };

      // const uploadedTableData = await confirmUploadedTable(params);

      // //生成的弹框显示报表
      // this.uploadList = uploadedTableData;
      // if (!this.uploadList) {
      //   this.$message({
      //     message: "获取表格数据出错！",
      //     type: "warning",
      //   });
      // }
      // delete this.uploadList.data[0]["余额表"];
      // delete this.uploadList.data[1]["余额表"];
      // for (var i in this.uploadList.data) {
      //   for (var j in this.uploadList.data[i]) {
      //     if (typeof this.uploadList.data[i][j] == "object") {
      //       this.newuploadList.push(this.uploadList.data[i][j]);
      //       let aa = 0;
      //       for (var m in this.uploadList.data[i][j]) {
      //         if (this.uploadList.data[i][j][m].uploadStatus == true) {
      //           aa += 1;
      //         }
      //       }
      //       this.uploadList.data[i][j].number = aa;
      //     }
      //   }
      // }
    },
    handleBtnStartClick() {
      this.$emit("BtnGenClicked");
    },
    async open() {
      await this.genUploadList(0);
      this.visible = true;
    },
  },
};
</script>

<style lang="stylus" scoped>
>>> .el-dialog__header {
  padding: 0;

  .el-dialog__title {
    font-size: 16px;
    padding: 16px 24px;
    display: block;
    width: 64px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
  }

  border-bottom: 1px solid #EEEEEE;
}

.table-container {
  max-height: 450px;
  overflow-y: auto;

  .table {
    width: 100%;
    margin: 0 auto;
    border-spacing: 0;

    tr {
      th {
        background: #EAEDF7;
        color: #7F9BC9;
        font-weight: normal;
        padding: 15px;
      }

      td {
        padding: 15px;
        border-left: 1px solid #EAEDF7;
      }

      td:last-child {
        border-right: 1px solid #EAEDF7;
      }
    }

    tr:nth-child(odd) {
      td {
        background: #F5F7FC;
      }
    }

    tr:last-child {
      td {
        border-bottom: 1px solid #F5F7FC;
      }
    }

    .statusup {
      text-align: center;
    }

    .statusup1 {
      color: #56D756;
      font-weight: bold;
    }
  }
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .redfont {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FAAD14;
    margin-right: 16px;
  }

  .btnGenerate {
    background: rgba(47, 84, 235, 1);
    border-radius: 4px;
    border: 0 none;
  }

  .btnGenerate:hover {
    background: rgba(47, 84, 235, 0.9);
  }
}

.done, .pedding, .delete, .perfect {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 7px;
  margin-bottom: 2px;
}

.done {
  background: rgba(82, 196, 26, 1);
}

.pedding {
  background: rgba(217, 217, 217, 1);
}
</style>
