<template>
  <div>
    <div class="chart-wrapper">
      <template
        v-for="(chart, index) in data.data.ruleOfInvoiceImageList"
      >
        <ThreeEchart
          :key="index"
          :data="chart"
          v-if="chart.imageType == 'histogram' || chart.imageType == 'twoHistogram'"
          class="chart"
          height="300px"
          :width="1200/data.data.ruleOfInvoiceImageList.length+'px'"
        ></ThreeEchart>
      </template>
    </div>
    <div class="table-wrapper">
      <BigPowerTable :data="data.data"></BigPowerTable>
    </div>
  </div>

</template>

<script>
import ThreeEchart from "@/pages/Result/ThreeYearResult/financialAnalysis/ThreeEchart";
import LineChart from "@/pages/Result/components/chats/LineChart";
import ColumnChart from "@/pages/Result/components/chats/ColumnChart";
import BigPowerTable from "big-power-table"

export default {
  name: "KeyTaxRate",
  props: {
    data: Object
  },
  components: {
    ThreeEchart,
    BigPowerTable
  },
  data() {
    return {

    }
  },
  methods: {}
}
</script>

<style lang="stylus" scoped>
.chart-wrapper
  display flex
</style>
