<template>
  <div class="main section" v-if="loaded">
    <div class="section-menu-bar">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-bar"
        mode="horizontal"
        @select="handleTopMenuSelect"
      >
        <el-menu-item index="1">财务分析</el-menu-item>
        <el-menu-item index="2">杜邦分析</el-menu-item>
      </el-menu>
    </div>
    <div v-if="activeIndex==1">
      <FinanceAnalyze :data="data.data[0].data"></FinanceAnalyze>
    </div>
    <div v-if="activeIndex==2">
      <DubanAnalyze :data="data.data[1].data" :zq="companyInfo.zq"></DubanAnalyze>
    </div>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import PurchaseAndSaleMoney
  from "@/pages/Result/ThreeYearResult/financialAnalysis/purchaseAndSale/PurchaseAndSaleMoney";
import {getCompanyInfo, getDupont} from "@/api/threeYears";
import PurchaseAndSaleCategoryCompare
  from "@/pages/Result/ThreeYearResult/financialAnalysis/purchaseAndSale/PurchaseAndSaleCategoryCompare";
import FinanceAnalyze
  from "@/pages/Result/ThreeYearResult/financialAnalysis/financeAndDuban/FinanceAnalyze";
import DubanAnalyze
  from "@/pages/Result/ThreeYearResult/financialAnalysis/financeAndDuban/DubanAnalyze";

export default {
  components: {
    FinanceAnalyze,
    DubanAnalyze
  },
  name: "FinanceAndDuban",
  data() {
    return {
      activeIndex: "1",
      loaded: false,
      data: null,
      companyInfo: null
    };
  },
  async mounted() {
    this.loaded = false
    await this.loadDupont();
    await this.loadCompanyInfo();
    this.loaded = true

  },
  methods: {
    async loadCompanyInfo() {
      const res = await getCompanyInfo({programId: this.$route.params.uuid})
      this.companyInfo = res.data
    },
    async loadDupont() {
      const res = await getDupont({programId: this.$route.params.uuid})
      this.data = res.data
    },
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
    },
  }
}
</script>

<style scoped>

</style>
