<template>
  <div>

    <div class="toolbar">
      <el-radio-group v-model="activeIndex" @change="change">
        <el-radio-button :value="index" :label="index" v-for="(item,index) in data"
                         :key="index">{{ item.name }}
        </el-radio-button>
      </el-radio-group>
      <button class="btn-link btn-fullscreen" @click="handleFullscreen">全屏</button>
    </div>
    <DubunSvg class="svg" v-if="data[activeIndex]" :data="data[activeIndex].data"></DubunSvg>

    <el-dialog
      :visible.sync="fullScreenDialogVisible"
      :fullscreen="true"
      :modal="false"
      title="杜邦分析"
    >
      <div class="svg-wrapper">
        <DubunSvg class="svg" v-if="data[activeIndex]" :data="data[activeIndex].data"></DubunSvg>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import DubunSvg from "@/pages/Result/ThreeYearResult/financialAnalysis/financeAndDuban/DubunSvg";

export default {
  components: {
    DubunSvg
  },
  name: "DubanAnalyze",
  props: {
    zq: String,
    data: Array
  },
  async mounted() {
    this.activeIndex = 0
  },
  data() {
    return {
      activeIndex: '',
      fullScreenDialogVisible: false
    }
  },
  computed: {
    theYear() {
      return parseInt(this.zq.replace("004"))
    },
    /**
     * 可选的年份
     * @returns {(number|number)[]}
     */
    yearsOption() {
      const zq = parseInt(this.zq.replace("004"))
      const ret = [zq, zq - 1, zq - 2]
      return ret
    }
  },
  methods: {
    change() {

    },
    handleFullscreen() {
      this.fullScreenDialogVisible = true
    }
  }
}
</script>

<style lang="stylus" scoped>
.toolbar
  margin-top 24px
  display flex
  justify-content space-between
  align-items center
  .btn-fullscreen {
    //position absolute
    //right 0;
    //top 0

  }

>>> .el-radio-button {
  margin-right 10px

  .el-radio-button__inner {
    border-radius: 2px;
    padding 6px 16px
    border 0 none;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #86909C;
    box-shadow none;
  }
}

>>> .el-radio-button.is-active {
  .el-radio-button__inner {
    background: rgba(22, 93, 255, 0.1);
    color: #165DFF;
    font-weight bold
  }
}

.svg-wrapper {
  width 100%;
  height calc(100vh - 55px)
  display flex
  align-items center
  justify-content center
}

.svg{
  margin-top 21px
}
</style>
