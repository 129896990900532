<template>
  <div>
    <div v-loading="loading" class="column2Chart">
<Line2Chart class="column2Chart" :chartData="data.jsonData"
                v-if="data&&data.jsonData"></Line2Chart>
    </div>
    
    <!--    <LineChart :data="chart" v-if="chart.imageType=='brokenLine'" width="849px"-->
    <!--               height="435px"></LineChart>-->
  </div>
</template>

<script>
import {getGXAmountCompare} from "@/api/threeYears";
import LineChart from "@/pages/Result/components/chats/LineChart";
import Line2Chart
  from "@/pages/Result/ThreeYearResult/financialAnalysis/purchaseAndSale/Line2Chart";

export default {
  components: {
    Line2Chart
  },
  name: "PurchaseAndSaleMoney",
  props: {
    taxNo: String,
    zq: [String, Number]
  },
  async mounted() {

    this.loading=true

    await this.loadGXAmountCompare();
  },
  data() {
    return {
      data: null,
      loading:null

    }
  },
  methods: {
    async loadGXAmountCompare() {
      const res = await getGXAmountCompare({
        taxNo: this.taxNo,
        zq: this.zq,
        programId: this.$route.params.uuid
      })
      this.data = res.data
    this.loading=false

    }
  }
}
</script>

<style lang="stylus" scoped>
.column2Chart
  width: 100%;
  height: 400px;
  margin-top 16px
</style>
