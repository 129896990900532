<template>
  <div class="bg">
    <div class="menu-bar-wrapper">
      <div class="main">
        <div class="menu-bar">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-bar"
            mode="horizontal"
            @select="handleTopMenuSelect"
          >
            <!-- <el-menu-item index="1">企业财税分析</el-menu-item> -->
            <el-menu-item index="1">财税风险详情</el-menu-item>
            <el-menu-item index="2"
              >发票查询</el-menu-item
            >
            <el-menu-item index="3">报表查询</el-menu-item>
          </el-menu>
          <div style="display: flex; align-items: center">
            <div
              @click="deleteBill"
              class="deleteBill"
              v-if="activeIndex == '2' && deleteType != 1"
            >
              删除发票
            </div>
            <!--            <button class="btn btn-primary btn-share" style="margin-left: 20px"-->
            <!--                    @click="handlePdfDownload">预览PDF-->
            <!--            </button>-->
          </div>
        </div>
      </div>
    </div>
    <div>
      <keep-alive>
      <!-- <FinancialAnalysis v-if="activeIndex == '1'"></FinancialAnalysis> -->
      <resultFinance
        :anchor="detailedAnchor"
        v-if="activeIndex == '1'"
        :reportType="3"
      ></resultFinance>
      <bill
        v-if="activeIndex == '2'"
        ref="bill"
        @deleteBillType="deleteBillType"
      ></bill>
      <search-table v-if="activeIndex == '3'"></search-table>
    </keep-alive>
    </div>
    <DesensitizationDialog
      ref="DesensitizationDialog"
      @confirm="handleDesensitizationDialogConfirm"
    ></DesensitizationDialog>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import FinancialAnalysis from "@/pages/Result/ThreeYearResult/financialAnalysis/FinancialAnalysis";

import bill from "@/pages/Result/ThreeYearResult/bill/detail";
import searchTable from "@/pages/Result/ThreeYearResult/searchTable/table";

import resultFinance from "@/pages/ResultEditor/ResultEditor";

import { deleteBill, getTaxFirmFindInvoice, getInvoiceAccess } from "@/api/api";
import DesensitizationDialog from "../../ResultEditor/components/DesensitizationDialog";

const pdfBase = process.env.VUE_APP_PDF_ROOT;
export default {
  components: {
    FinancialAnalysis,
    bill,
    searchTable,
    resultFinance,
    DesensitizationDialog,
  },
  mounted() {
    this.$store.commit("ResultEditor/setOpenMode", { mode: "threeYears" });
    this.$eventBus.$on("switchToDetailTab", (data) => {
      const { anchor } = data;
      this.activeIndex = "2";
      this.detailedAnchor = anchor;
      this.$store.commit("ResultEditor/setOpenMode", { mode: "threeYears" });
    });
    // 
    this.getTaxPaymentType();
  },
  name: "ThreeYearResult",
  data() {
    return {
      activeIndex: sessionStorage.getItem("threeActiveIndex") || "1",
      deleteType: 1,
      detailedAnchor: "",
      getTaxFirmFindInvoiceStatus: false,
    };
  },
  computed: {},
  methods: {
    handlePdfDownload() {
      this.$refs["DesensitizationDialog"].open();
    },
    handleDesensitizationDialogConfirm(isDesensitization) {
      isDesensitization = isDesensitization ? 1 : 0;
      const token = localStorage.getItem("authorization");
      const pdfDownloadUrl = `${pdfBase}/pdfFrontGenerator/${this.$route.params.uuid}/${this.$route.params.nsrsbh}?token=${token}&desensitization=${isDesensitization}`;
      window.open(
        pdfDownloadUrl,
        "_blank" // <- This is what makes it open in a new window.
      );
      this.$refs["DesensitizationDialog"].close();
    },
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
      window.scrollTo(0, 0);
      this.detailedAnchor = "";
      sessionStorage.setItem("threeActiveIndex", key);
      this.$store.commit("ResultEditor/setOpenMode", { mode: "threeYears" });
    },
    //
    deleteBill() {
      this.$confirm(
        "发票删除后将不再显示发票信息，且无法恢复只能重新授权获取，是否删除？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteBill(this.$route.params.uuid).then((res) => {
            this.$message({
              message: res.message,
              type: "success",
            });
            if (res.code == 200) {
              this.$refs.bill.getCurrentData();
            }
          });
        })
        .catch(() => {});
    },
    deleteBillType(data) {
      this.deleteType = data;
    },
    taxFirmFindInvoice() {
      getTaxFirmFindInvoice().then((res) => {
        this.getTaxFirmFindInvoiceStatus = res.data;
      });
    },
    // 查看是否存在错误数据  接口有点慢 无法在detect组件调用 只能放这慢慢调用等结果
    getTaxPaymentType() {
      getInvoiceAccess(this.$route.params.uuid).then(res=> {
        if(!res.data) return;
        if(res.data.showData && res.data.text) {
          this.$notify({
            title: '警告',
            message: res.data.text,
            type: 'warning',
            duration: 0
          });
        }
        if(!res.data.showData) {
          this.$router.push({
            path: "/test/test-noData",
          });
        }
      })
    }
  },
};
</script>

<style lang="stylus" scoped>
.bg
  background: #FDFDFD;
  padding-top: 42px;

.menu-bar-wrapper
  position: fixed;
  z-index: 200;
  top: 0;
  width: 100%;
  height: 42px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.07);

  .menu-bar
    display flex
    justify-content space-between
    align-items center

    >>> .el-menu--horizontal
      .el-menu-item
        padding 0px 12px
        font-size: 16px;
        font-weight: 600;
        color: #4E5969;
        margin 0 25px
        height 42px;
        line-height 42px

      .el-menu-item.is-active
        border-bottom: 2px solid #165DFF;
        color: #165DFF;

    .btn-share
      padding 0
      width: 84px;
      height: 28px;
      background: #165DFF;
      border-radius: 2px;
      display flex;
      align-items center
      justify-content center

.deleteBill {
  color: #165DFF;
  cursor: pointer;
}
</style>
