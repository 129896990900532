<template>
  <div class="main section" v-if="loaded">
    <div class="section-menu-bar">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-bar"
        mode="horizontal"
        @select="handleTopMenuSelect"
      >
        <el-menu-item index="1">历史纳税情况</el-menu-item>
      </el-menu>
    </div>
    <div v-if="activeIndex==1">
      <div class="table-wrapper">
        <TaxCreditLevel :data="data.data[0]" style="width: 360px"></TaxCreditLevel>
        <TaxCreditLevel :data="data.data[1]" style="width: 780px"></TaxCreditLevel>
      </div>

    </div>

  </div>
</template>

<script>
import TaxCreditLevel
  from "@/pages/Result/ThreeYearResult/financialAnalysis/historyTaxCondition/TaxCreditLevel";
import {getTaxPayment} from "@/api/threeYears";

export default {
  name: "HistoryTaxCondition",
  components: {
    TaxCreditLevel
  },
  async mounted() {
    this.loaded = false
    await this.loadTaxPayment();
    this.loaded = true
  },
  data() {
    return {
      activeIndex: "1",
      loaded: false,
      data: null,
    };
  },
  methods: {
    async loadTaxPayment() {
      const res = await getTaxPayment({programId: this.$route.params.uuid})
      this.data = res.data
    },
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
    },
  }
}
</script>

<style lang="stylus" scoped>
.table-wrapper
  display flex
  justify-content space-between

  >>> .bigPowerTable {
    max-height 480px
    overflow auto
  }
</style>
