<template>
  <div>
    <div class="name">{{ data.name }}</div>
    <BigPowerTable :data="data.data"></BigPowerTable>
  </div>
</template>

<script>
export default {
  name: "TaxCreditLevel",
  props: {
    data: [Object]
  }
}
</script>

<style lang="stylus" scoped>
.name
  font-size: 14px;
  font-weight: 400;
  color: #4E5969;
  line-height: 20px;
  margin-top 24px;
  margin-bottom 16px
</style>
