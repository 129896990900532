<template>
  <div class="wrapper">
    <div class="radar-wrapper">
      <div class="summary-evaluate">
        <div class="name">综合评分</div>
        <div class="rate">
          <el-rate
            :value="data.score/20"
            :max="max"
            disabled
            text-color="#FF8023"
            :colors="['#FF8023','#FF8023','#FF8023']"
          >
          </el-rate>
          <div class="rate-score">{{ data.score }}分</div>
        </div>
      </div>

      <RadarChart :data="radarData"></RadarChart>
    </div>
    <div class="table-wrapper">
      <BigPowerTable :data="data.data" class="table"></BigPowerTable>
    </div>

  </div>
</template>

<script>
import RadarChart from "@/pages/Result/components/ResultHead/RadarChart";

export default {
  name: "FinanceAnalyze",
  components: {
    RadarChart
  },
  props: {
    data: Object
  },
  data() {
    return {
      currentAbilityIndex: null,
      max: 5,
      value: 3.7
    }
  },
  computed: {
    radarData() {
      return this.data.data.extendedData.map(v => {
        return {
          name: v.name,
          value: v.score
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.wrapper
  display flex;
  margin-top 24px

  >>> .el-rate__icon
    font-size 22px
  .radar-wrapper
    display flex
    flex-direction column
    width 480px
  .summary-evaluate
    display flex
    align-items center

    .name
      font-size: 16px;
      color: #666
      margin-right 10px

    .rate
      display flex
      align-items center
      position relative
      top -2px

      .rate-score
        color rgb(255, 128, 35);
        font-size 14px
.table-wrapper
  flex-grow 1
.table
  >>>.bigPowerTable
    max-height 468px
</style>
