<template>
  <div>
    <el-tabs tab-position="left">
      <el-tab-pane :label="key" v-for="(item, key) in table" :key="key">
        <div class="table-container">
          <table class="table">
            <tr>
              <th v-if="item[0].tableNum">表单编号</th>
              <th>表单名称</th>
            </tr>

            <tr v-for="v in item" :key="v.tableNameEn">
              <td v-if="v.tableNum">{{ v.tableNum }}</td>
              <td>{{ v.tableNameCn }}</td>
            </tr>
          </table>
        </div>
        <div class="table-info">
          <div class="table-content">
            <div>1.系统支持单个文件、多个文件、文件夹的形式上传</div>
            <div>
              2.系统支持上传Excel、HTML等格式文件，同时支持一个Excel内包含多个sheet页
            </div>
            <div>
              3.系统支持手工填报、多个文件批量导入（批量导入最多20个文件，大小5M以内）
            </div>
            <div>
              4.为确保数据准确提取，需保证文件命名、sheet页命名规范（命名规范以图上为例）
            </div>
            <div>
              5.为提升检测结果准确性，建议企业上传本期与上期数据以便进行数据分析比对
            </div>
          </div>
          <div class="table-btn">
            <div class="table-text">请仔细阅读我们支持的报表分类</div>
            <button class="table-confirm" @click="sendTofalse">确认</button>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { showModules } from "@/api/api";
import { mapState } from "vuex";

export default {
  components: {},
  props: {
    bankId: String,
  },

  computed: {
    ...mapState(["companyInfo"]),
  },
  methods: {
    sendTofalse() {
      this.$emit("acknowledgeDialogVisible", "false");
    },
  },
  mounted() {
    this.activeName = "0";

    const para = {
      bankId: this.bankId,
      qykjzd: "qykjzd",
    };
    showModules(para).then((res) => {
      const data = {};

      if (res.msgCode == 1) {
        this.table = JSON.parse(res.data);
      }
    });
  },
  data() {
    return {
      activeName: "0",
      table: null,
      acknowledgeDialogVisible: false,
    };
  },
};
</script>

<style lang="stylus" scoped>
.table {
  width: 90%;
  margin: 0 auto;
  border-spacing: 0;

  tr {
    th {
      background: #EAEDF7;
      color: #7F9BC9;
      font-weight: normal;
      padding: 15px;
    }

    td {
      padding: 15px;
      border-left: 1px solid #EAEDF7;
    }

    td:last-child {
      border-right: 1px solid #EAEDF7;
    }
  }

  tr:nth-child(odd) {
    td {
      background: #F5F7FC;
    }
  }

  tr:last-child {
    td {
      border-bottom: 1px solid #F5F7FC;
    }
  }
}

.tab-wrapper {
  background: #eaedf7;
}

>>> {
  .el-tabs__item {
    height: 60px;
    font-size: 14px;
    line-height: 60px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .el-tabs__header.is-left {
    margin-right: 0;
    background: #eaedf7;
    font-size: 14px;
  }

  .el-tabs__item.is-left {
    text-align: left;
    padding: 0 20px;
    color: #7f9bc9;
  }

  .el-tabs__item.is-active {
    background: #fff;
    color: #007aff;
  }

  .el-tabs__active-bar {
    width: 0 !important;
  }

  .el-tabs__content {
  }

  .el-tabs__nav-wrap::after {
    content: none;
  }
}

.tab-content-wrapper {
  background: #fff;
  padding: 0 30px 0px 30px;
}

>>> .el-tab-pane {
  padding-bottom: 50px;
}

.table-info {
  display: flex;
  color: #007AFF;
  font-size: 14px;
  width: 90%;
  margin: 0 auto;
  padding-top: 30px;
  line-height: 1.3;

  .table-content {
    padding-right: 20px;
    width :60%;
  }

  .table-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    position:absolute;
    right:5%;
    .table-text {
      color: #6B7897;
      line-height: 1;
    }
    .table-confirm {
      width: 210px;
      height: 50px;
      line-height: 50px;
      font-size:17px;
      color: #ffffff;
      background: rgba(0, 122, 255, 1);
      border-radius: 6px;
      margin: 0;
      padding: 0;
      border: 1px solid transparent;
      outline: none;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}

.table-container {
  max-height: 450px;
  overflow-y: auto;
}
</style>
