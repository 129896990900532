<template>
  <div class="main section" v-load="!loaded" style="min-height: 200px">
    <div v-if="loaded">
      <div class="section-menu-bar">
        <el-menu :default-active="activeIndex" class="el-menu-bar" mode="horizontal" @select="handleTopMenuSelect">
          <el-menu-item index="1">购销金额波动</el-menu-item>
          <el-menu-item index="2">购销品类对比</el-menu-item>
        </el-menu>
      </div>
      <div v-if="activeIndex == 1">
        <keep-alive>
          <PurchaseAndSaleMoney :taxNo="companyInfo.taxNo" :zq="companyInfo.zq"></PurchaseAndSaleMoney>
        </keep-alive>
      </div>
      <div v-if="activeIndex == 2">
        <keep-alive>
          <PurchaseAndSaleCategoryCompare :taxNo="companyInfo.taxNo" :zq="companyInfo.zq">
          </PurchaseAndSaleCategoryCompare>
        </keep-alive>

      </div>
    </div>


  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import PurchaseAndSaleMoney
  from "@/pages/Result/ThreeYearResult/financialAnalysis/purchaseAndSale/PurchaseAndSaleMoney";
import { getCompanyInfo } from "@/api/threeYears";
import PurchaseAndSaleCategoryCompare
  from "@/pages/Result/ThreeYearResult/financialAnalysis/purchaseAndSale/PurchaseAndSaleCategoryCompare";
export default {
  components: {
    PurchaseAndSaleMoney,
    PurchaseAndSaleCategoryCompare
  },
  name: "PurchaseAndSale",
  data() {
    return {
      activeIndex: "1",
      loaded: false,
      companyInfo: {}
    };
  },
  async mounted() {
    this.loaded = false
    await this.loadCompanyInfo();
    this.loaded = true

  },
  methods: {
    async loadCompanyInfo() {
      const res = await getCompanyInfo({ programId: this.$route.params.uuid })
      this.companyInfo = res.data
    },
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
    },
  }
}
</script>

<style scoped>

</style>
