<template>
  <div>
    <BigPowerSwitcher v-model="showType" class="switcher"></BigPowerSwitcher>
    <div class="toolbar">
      <el-radio-group v-model="activeIndex" @change="loadCompanyTopClassRisk">
        <el-radio-button :label="year" v-for="(year, index) in yearsOption" :key="index">{{ year }}年
        </el-radio-button>
      </el-radio-group>

    </div>
    <template v-for="(year, index) in yearsOption">
      <div v-if="activeIndex == year" :key="index">
        <div class="tipBox" v-if="data && data.textList">
          <div v-for="(text, index) in data.textList" :key="index">{{ text }}</div>
        </div>
        <div class="tip" v-if="showType == 'table'">采购金额排序：按{{ year }}年不含税金额占比排序，前
          <BigPowerInput type="number" :min="1" v-model="filter.limit" size="mini" style="width: 60px"
            @change="loadCompanyTopClassRisk" :max="50"></BigPowerInput>
          的采购品类如下：
        </div>
      </div>
    </template>
    <div v-loading="loading">
      <BigPowerTable v-if="showType == 'table' && data && data.dataTable" :data="data.dataTable" class="table">
      </BigPowerTable>

      <template v-else-if="data && data.ruleOfInvoiceImage">

        <div class="chart-wrapper">
          <Pie :data="data.ruleOfInvoiceImage" :key="activeIndex" v-if="data.ruleOfInvoiceImage.imageType == 'annulus'"
            width="756px" height="500px"></Pie>

        </div>

      </template>


    </div>

  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl"
import ChinaCityTable from "@/pages/Result/ThreeYearResult/financialAnalysis/ChinaCityTable";
import { getCompanyAreaList, getCompanyTopClassRisk } from "@/api/threeYears";
import BigPowerTable from "big-power-table";
import BigPowerSwitcher from "@/pages/Result/ThreeYearResult/components/BigPowerSwitcher";
import Pie from "@/pages/ResultEditor/components/InvoiceIndex/components/Pie";

export default {
  name: "PurchaseAndSaleTabItem",
  props: {
    zq: String,
    taxNo: String,
    companyType: String
  },
  components: {
    Pie,
    BigPowerTable,
    BigPowerSwitcher
  },
  data() {
    return {
      activeIndex: '',
      showType: "table",
      filter: {
        time: [],
        limit: 15
      },
      data: null,
      loading: true
    };
  },
  watch: {
    showType(value) {
      if (value !== 'table') {
        this.loading = true
        this.loadCompanyTopClassRisk()
      }
    },
    activeIndex() {
      if (this.data) {
        this.loading = true

        this.data.ruleOfInvoiceImage = null;
        this.loadCompanyTopClassRisk()
      }


    },
    async companyType() {
      if (this.data) {
        this.loading = true

        this.data.ruleOfInvoiceImage = null;
        this.loadCompanyTopClassRisk()
      }
    }
  },
  async mounted() {
    this.activeIndex = this.theYear
    await this.loadCompanyTopClassRisk();

  },
  computed: {
    theYear() {
      return parseInt(this.zq.replace("004"))
    },
    /**
     * 可选的年份
     * @returns {(number|number)[]}
     */
    yearsOption() {
      const zq = parseInt(this.zq.replace("004"))
      const ret = [zq, zq - 1, zq - 2]
      return ret
    }
  },
  methods: {

    async loadCompanyTopClassRisk() {
      this.data = null
      const limit = this.showType == 'table' ? this.filter.limit : 10;
      const res = await getCompanyTopClassRisk({
        taxNo: this.taxNo,
        year: this.activeIndex,
        showSize: limit,
        companyType: this.companyType,
        zq: this.zq
      });
      this.data = res.data
      this.$emit('loadCompanyTopClassRisk')
      this.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.toolbar
  margin-top 24px
  display flex
  justify-content space-between
  align-items center

.tip
  margin-top 21px
  margin-bottom 23px
  font-size: 14px;
  font-weight: 400;
  color: #86909C;
  line-height: 20px;

>>> .el-radio-button {
  margin-right 10px

  .el-radio-button__inner {
    border-radius: 2px;
    padding 6px 16px
    border 0 none;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #86909C;
    box-shadow none;
  }
}

>>> .el-radio-button.is-active {
  .el-radio-button__inner {
    background: rgba(22, 93, 255, 0.1);
    color: #165DFF;
    font-weight bold
  }
}

.tipBox {
  width 100%
  padding 12px 16px
  background: rgba(148, 191, 255, 0.1);
  border-radius: 2px;
  box-sizing border-box
  font-size: 14px;
  font-weight: 400;
  color: #4E5969;
  line-height: 20px;
  margin-bottom 16px
  margin-top 30px
}

.switcher
  position absolute;
  top 15px
  right 0

.chart-wrapper
  display flex
  justify-content center
  margin-top 10px
</style>
