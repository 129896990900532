import { render, staticRenderFns } from "./CompanyRiskLookup.vue?vue&type=template&id=61b0375a&scoped=true&"
import script from "./CompanyRiskLookup.vue?vue&type=script&lang=js&"
export * from "./CompanyRiskLookup.vue?vue&type=script&lang=js&"
import style0 from "./CompanyRiskLookup.vue?vue&type=style&index=0&id=61b0375a&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61b0375a",
  null
  
)

export default component.exports