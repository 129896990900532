<template>
  <el-dialog
    id="status_upload"
    title="正在检测"
    :show-close="false"
    :close-on-click-modal="false"
    :visible.sync="show"
    width="712px"
  >
    <div class="content-wrapper">
      <div class="content-inner">
        <img
          :src="`https://shuian-file.oss-cn-hangzhou.aliyuncs.com/aitax/loading_test.gif?cache=${rand}`"
          class="loading-pic"
        />
        <div class="scroll-wrapper">
          <div class="masker"></div>
          <vueSeamlessScroll :data="text" class="seamless-warp" :class-option="classOption">
            <div class="text" v-for="aText in text" :key="aText">{{aText}}</div>
          </vueSeamlessScroll>
        </div>
      </div>
      <div class="progress-bar" :style="{width:100*(time)/maxTime+'%'}"></div>
    </div>
  </el-dialog>
</template>

<script>
import Vue from "vue";
import vueSeamlessScroll from "vue-seamless-scroll";
Vue.use(scroll);

export default {
  components: {
    vueSeamlessScroll
  },
  data: function() {
    return {
      rand: 1,
      classOption: {
        hoverStop: false
      },
      maxTime: 5,
      time: 0,
      timeHandle: null,
      show: false,
      text: [
        "正在检测货币资金异常变动",
        "正在检测应收账款匹配风险",
        "正在检测应收票据对应风险",
        "正在检测资金无偿占用风险",
        "正在检测往来账款异常变动风险",
        "正在检测隐瞒金融交易风险",
        "正在检测存货异常变动风险",
        "正在检测存货与收入匹配风险",
        "正在检测固定资产异常变动风险",
        "正在检测固定资产折旧异常风险",
        "正在检测固定资产与收入匹配风险",
        "正在检测无形资产摊销异常风险",
        "正在检测资产与收入变动差异风险",
        "正在检测资产抵债风险",
        "正在检测存货库存异常风险",
        "正在检测长期股权投资异常变动风险",
        "正在检测以实物对外投资风险",
        "正在检测资产评估异常风险",
        "正在检测长期待摊摊销异常风险",
        "正在检测增值税税负率异常风险",
        "正在检测增值税与收入匹配风险",
        "正在检测增值税负率比对行业异常风险",
        "正在检测进项税额异常变动风险",
        "正在检测应纳税额勾稽错误",
        "正在检测应抵扣税额勾稽风险",
        "正在检测销项匹配风险",
        "正在检测进项匹配风险",
        "正在检测进销项匹配风险",
        "正在检测职工薪酬匹配风险",
        "正在检测个税相关风险",
        "正在检测职工薪酬与收入匹配风险",
        "正在检测职工教育经费异常风险",
        "正在检测实收资本匹配异常风险",
        "正在检测未分配利润匹配风险",
        "正在检测收入异常变动风险",
        "正在检测视同销售匹配风险",
        "正在检测收入与利润配比风险",
        "正在检测延迟收入风险",
        "正在检测成本异常变动风险",
        "正在检测费用发票异常风险",
        "正在检测研发费用异常风险",
        "正在检测毛利率变动风险",
        "正在检测费用异常变动风险",
        "正在检测发票税率异常风险",
        "正在检测资产处置异常风险",
        "正在检测资产损失异常风险",
        "正在检测投资收益异常风险",
        "正在检测科目核算异常风险",
        "正在检测期间费用变动系数异常风险",
        "正在检测销售费用与收入匹配风险",
        "正在检测营业外支出异常变动风险",
        "正在检测利息支出异常风险",
        "正在检测报表勾稽风险",
        "正在检测税会差异风险",
        "正在检测特殊税收优惠风险",
        "正在检测现金流匹配风险",
        "正在检测现金流量表与利润表匹配风险",
        "正在检测非货币资金交换异常风险",
        "正在检测企业所得税贡献率与行业差异风险",
        "正在检测企业所得税贡献率变动差异风险"
      ]
    };
  },
  methods: {
    open() {
      this.show = true;
      this.time = 0;
      this.rand = Math.round(Math.random() * 100000);

      this.timeHandle = setInterval(() => {
        if (this.time >= this.maxTime) {
          this.$emit("complete");
          clearInterval(this.timeHandle);
        } else {
          this.time++;
        }
      }, 1000);
    }
  }
};
</script>

<style lang="stylus" scoped>
.scroll-wrapper {
  position: relative;

  .masker {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 1));
    height: 100px;
    z-index: 3000;
  }
}

.seamless-warp {
  height: 200px;
  overflow: hidden;

  .text {
    font-size: 16px;
    color: #333;
    margin: 12px 0;
  }
}

>>> .el-dialog__header {
  
  .el-dialog__title {
    font-size: 16px;
    padding: 0px px;
    display block;
    font-weight bold
  }

  border-bottom: 1px solid #EEEEEE;
}

>>> .el-dialog__body {
  padding: 0;
}

.content-inner {
  padding: 30px 20px;
  display: flex;
  justify-content: space-evenly;

  .loading-pic {
    width: 200px;
    height: 200px;
  }
}

.content-wrapper {
  .progress-bar {
    height: 8px;
    background: #22BD71;
    width: 0%;
    transition: all 0.5s;
  }
}
</style>