<template>
  <div class="main section" v-if="loaded">
    <div class="title-wrapper">
      <div class="title">
        财税风险检测报告
      </div>
      <div class="release-date">
        报告时间：{{data.exeDate}}
      </div>
    </div>
    <div class="company-name">
      {{data.companyName}}
    </div>
    <div class="basic-infos">
      <div class="basic-info-item">
        <div class="label">所属行业：</div>
        <div class="value">{{ data.hymc }}</div>
      </div>
      <div class="basic-info-item">
        <div class="label">注册资本：</div>
        <div class="value">{{ data.regCapital }}</div>
      </div>
      <div class="basic-info-item">
        <div class="label">纳税人识别号：</div>
        <div class="value">{{ data.taxNo }}</div>
      </div>

      <div class="basic-info-item">
        <div class="label">纳税人资质：</div>
        <div class="value">{{ data.taxpayerQualificationType }}</div>
      </div>
      <div class="basic-info-item">
        <div class="label">实收资本：</div>
        <div class="value">{{ data.actualCapital }}</div>
      </div>
      <div class="basic-info-item">
        <div class="label">进出口信用：</div>
        <div class="value">{{ data.managementCategory }}</div>
      </div>
      <div class="basic-info-item">
        <div class="label">成立日期：</div>
        <div class="value">{{ data.estiblishTime }}</div>
      </div>
      <div class="basic-info-item">
        <div class="label">核准日期：</div>
        <div class="value">{{ data.approvedTime }}</div>
      </div>
      <div class="basic-info-item">
        <div class="label">参保人数：</div>
        <div class="value">{{ data.socialStaffNum }}</div>
      </div>




      <!--                  <template v-if="data.managementCategory!='无'">-->
      <!--                    <div class="basic-info-item">报关有效期：{{ data.validityDate }}</div>-->
      <!--                    <div class="basic-info-item">海关注销标志：{{ data.status}}</div>-->
      <!--                  </template>-->
    </div>
  </div>
  <div class="main section" v-else>
    <BigPowerLoading height="210px"></BigPowerLoading>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl"
import {getCompanyInfo} from "@/api/threeYears";
export default {
  name: "Summary",
  data() {
    return {
      data: {},
      loaded:false
    }
  },
  async mounted() {
    this.loaded=false
    await this.loadCompanyInfo()
    this.loaded=true
  },
  methods:{
    async loadCompanyInfo(){
      const res = await getCompanyInfo({programId:this.$route.params.uuid,desensitizationStatus:this.$route.query.desensitization})
      this.data = res.data
    }
  }
}
</script>

<style lang="stylus" scoped>
.title-wrapper
  display flex
  align-items center
  justify-content space-between

  .title
    font-size: 18px;
    font-weight: 400;
    color: #1D2129;
    line-height: 25px;

  .release-date
    font-weight: 400;
    color: #86909C;
    line-height: 20px;

.company-name
  font-size: 24px;
  font-weight: 600;
  color: #1D2129;
  line-height: 33px;
  margin-top 16px
  margin-bottom 24px

.basic-infos
  margin-top 40px
  display flex;
  flex-wrap wrap;

  .basic-info-item
    width 33.3%
    margin-bottom 16px;
    display flex
    align-items center

    .label
      font-size: 14px;
      color: #86909C;
    .value
      font-size: 14px;
      color: #1D2129;

</style>
