<template>
  <div>
    <!--上传了哪些表-->
    <CheckTable
      :bankId="this.bankId"
      :productID="this.productID"
      :zqs="zqs"
      :uuid="uuid"
      ref="checkTable"
      @BtnGenClicked="handleGenBtnClicked"
    ></CheckTable>
    <!--/上传了哪些表-->
    <!--检测动效-->
    <loadingTest @complete="handleLoadingTestComplete" ref="loadingTest"></loadingTest>
    <!--/检测动效-->
  </div>
</template>

<script>
import CheckTable from "./CheckTable";
import LoadingTest from "./LoadingTest";
import { previousZq } from "@/utils/helper";
import { mapState } from "vuex";
import { genTestReport, mateGoods } from "@/api/api";

export default {
  components: {
    CheckTable,
    LoadingTest,
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("UploadTable", ["permissionID"]),
  },
  props: {
    bankId: String,
    productID: [String, Number],
    productName: String,
    uuid: String,
    zq: String,
    version: [Number, String],
    inVoiceMobile: [String, Number],
    inVoiceTaxPayerID: [String, Number],
    inVoiceEmail: String,
    inVoiceCompanyName: String,
    deleteDataType: [Number, String],
  },
  data: function () {
    return {
      zqs: [],
      isAutoDataNum: 0,
    };
  },
  mounted() {
    this.actualZq = previousZq(this.zq);
    this.zqs.push(this.zq);
    this.zqs.push(this.actualZq);
  },
  methods: {
    //确认检测对话框的开始检测按钮按下
    handleGenBtnClicked() {
      this.$refs["checkTable"].showCheckports = false;
      this.$refs["loadingTest"].open();
    },
    //检测动画结束
    handleLoadingTestComplete() {
      this.handleBtnStartClick();
    },
    handleBtnNext2Click() {
      this.$refs["checkTable"].open();
    },
    async handleBtnStartClick() {
      // this.tableCards.reduce(uploaded=>{
      //   return uploaded+uploaded;
      // },0)
      this.loading1 = true;
      if (!this.$store.state.UploadTable.permissionID) {
        //this.$alert("权限校验已过期，请重新操作").then(() => {
        this.$router.push({ name: "test-hub" });
        //});
      } else {
        const zq = this.zq;
        if (this.version == 17) {
          this.isAutoDataNum = 1;
        } else {
          this.isAutoDataNum = 0;
        }
        const gentestReportRes = await genTestReport({
          appId: localStorage.getItem("appid_3rd"),
          token: this.authorization,
          nsrsbh: this.companyInfo.nsrsbm,
          tag: this.version,
          uuid: this.uuid,
          zq,
          invoiceNsrsbh: this.inVoiceTaxPayerID,
          getDataWay: 1,
          deleteDataType: this.deleteDataType,
          nameless: sessionStorage.getItem("isAnonymous") === "true",
        });

        if (gentestReportRes.msgCode == 1) {
          this.loading1 = false;
          //设置permissionID
          this.$store.commit("UploadTable/setPermissionID", {
            permissionID: "",
          });

          // this.$store.commit("Result/setIsFromUpload", true);
          this.$router.push({
            name: "test-InvoiceUpload",
          });
          // this.$router.push({
          //   name: "result",
          //   params: { uuid: this.uuid },
          //   query: {
          //     bankId: this.bankid,
          //     zq,
          //     uuid: this.uuid,
          //     nsrsbh: this.companyInfo.nsrsbm,
          //     name: this.produName,
          //   },
          // });
          this.showCheckports = false;
        } else {
          this.$alert(gentestReportRes.msgInfo, "温馨提示", {
            confirmButtonText: "确定",
            callback: (action) => {
              this.$router.push({ name: "homePage" });
            },
          });
          this.loading1 = false;
        }
      }
    },
  },
};
</script>

<style lang="stylus" scoped></style>
