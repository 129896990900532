<template>
  <div>
    <div class="tip">按不含税金额占比排序，前 <BigPowerInput type="number" :min="1" v-model="filter.limit" size="mini"
        style="width: 60px" @change="loadGXCategoryAnalysis"></BigPowerInput>的购销对比如下：</div>
    <div v-loading="loading">
      <BigPowerTable :data="data" class="table"></BigPowerTable>
    </div>
    <PurchaseAndSaleTab :taxNo="taxNo" :limit="filter.limit"></PurchaseAndSaleTab>
  </div>
</template>

<script>
import BigPowerTable from "big-power-table";
import { getGXCategoryAnalysis } from "@/api/threeYears";
import "@/pages/Result/ThreeYearResult/global.styl"
import PurchaseAndSaleTab
  from "@/pages/Result/ThreeYearResult/financialAnalysis/purchaseAndSale/PurchaseAndSaleTab";

export default {
  components: {
    BigPowerTable,
    PurchaseAndSaleTab
  },
  props: {
    taxNo: String,
    zq: [String, Number]
  },
  name: "PurchaseAndSaleCategoryCompare",
  async mounted() {
    this.loading = true

    await this.loadGXCategoryAnalysis();
  },
  data() {
    return {
      data: null,
      loading: false,
      filter: {
        limit: 15
      }
    }
  },
  methods: {
    async loadGXCategoryAnalysis() {
      const res = await getGXCategoryAnalysis({
        taxNo: this.taxNo,
        zq: this.zq,
        topCount: this.filter.limit,
        programId: this.$route.params.uuid
      })
      this.data = res.data
      this.loading = false

    }
  }
}
</script>

<style lang="stylus" scoped>
.tip
  margin-top 29px
  font-size: 14px;
  font-weight: 400;
  color: #86909C;
  line-height: 20px;
.table
  margin-top 21px
</style>
