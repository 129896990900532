<template>
  <div>

    <div>
      <NetWorkChartWindow></NetWorkChartWindow>
      <CompanyRiskLookup></CompanyRiskLookup>
    </div>
    <div>

    </div>




  </div>

</template>

<script>
import CompanyRiskLookup from "@/pages/Result/ThreeYearResult/financialAnalysis/companyRisk/CompanyRiskLookup";
import {getCompanyAreaList, getCompanyRiskImage} from "@/api/threeYears";
import NetWorkChartWindow
  from "@/pages/Result/ThreeYearResult/financialAnalysis/networkChart/NetWorkChartWindow";

export default {
  name: "UpDownCustomerNetWork",
  components:{
    CompanyRiskLookup,
    NetWorkChartWindow
  },
  data() {
    return {


    }
  },
  mounted() {

  },
  methods:{

  }
}
</script>

<style lang="stylus" scoped>

</style>
