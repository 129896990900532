var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bill-tableListId",attrs:{"id":"tableListId"}},[(_vm.tables)?_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.filtedTables),function(table,group,index){return _c('el-tab-pane',{key:group,attrs:{"label":`${group}(${table.uploadNum}/${table.totalNum})`,"name":index.toString()}},[(table.uploadNum != '余额表')?_c('el-table',{staticStyle:{"width":"100%","margin-top":"5px"},attrs:{"data":table.list,"row-key":"id","default-sort":{ prop: 'status', order: 'ascending' },"tree-props":{ children: 'children' }}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"120"}}),_c('el-table-column',{attrs:{"prop":"name","label":"报表名称","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(scope.row.tableNum)+" "+_vm._s(scope.row.name)+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"status","label":"状态","width":"180","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row.children.length == 0)?[(scope.row.status === '已上传')?_c('span',[_c('span',{staticClass:"circle"}),_c('span',[_vm._v("已上传")])]):(scope.row.status === '待修改')?_c('span',{staticClass:"status-error"},[_c('span',{staticClass:"circle circle-eidt"}),_c('span',[_vm._v("待修改")])]):_c('span',[_c('span',{staticClass:"circle circle-no"}),_c('span',[_vm._v(_vm._s(scope.row.status))])])]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"label":"操作","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row.children.length == 0)?[(scope.row.status === '已上传')?[(scope.row.name.indexOf('余额表') == -1)?_c('router-link',{staticClass:"btn btn-view-edit",attrs:{"to":{
                  name: _vm.manualTableRouteName,
                  params: {
                    templateID: scope.row.templateId,
                    from: _vm.manner,
                    productID: _vm.product.id ? _vm.product.id : 191,
                    productName: _vm.product.name || '无',
                    zqLocation: _vm.zqLocation,
                  },
                  query: {
                    tableName: scope.row.name,
                    zq: scope.row.zq,
                    productID: _vm.product.id ? _vm.product.id : 191,
                    productName: _vm.product.name,
                    bankID: _vm.product.bankID,
                    activeName: _vm.activeName,

                    uuid: _vm.uuid,
                    templateID: scope.row.templateId,
                    companyName: _vm.$route.query.companyName,
                    taxNo: _vm.$route.query.taxNo,
                    nsrsbh: _vm.$route.query.nsrsbh,
                    fromHouTai: _vm.$route.query.fromHouTai,
                  },
                }}},[_c('i',{staticClass:"el-icon-edit-outline"})]):_vm._e(),(scope.row.name.indexOf('余额表') != -1)?_c('router-link',{attrs:{"to":{
                  name: _vm.manualTableRouteName,
                  params: {
                    templateID: scope.row.templateId,
                    from: _vm.manner,
                    productID: _vm.product.id ? _vm.product.id : 191,
                    productName: _vm.product.name || '无',
                    zqLocation: _vm.zqLocation,
                  },
                  query: {
                    tableName: scope.row.name,
                    zq: scope.row.zq,
                    productID: _vm.product.id ? _vm.product.id : 191,
                    productName: _vm.product.name,
                    bankID: _vm.product.bankID,
                    activeName: _vm.activeName,

                    uuid: _vm.uuid,
                    templateID: scope.row.templateId,
                    companyName: _vm.$route.query.companyName,
                    taxNo: _vm.$route.query.taxNo,
                    nsrsbh: _vm.$route.query.nsrsbh,
                    fromHouTai: _vm.$route.query.fromHouTai,
                    go: 'yeb',
                  },
                }}},[_c('i',{staticClass:"el-icon-edit-outline"})]):_vm._e(),_c('button',{staticClass:"btn btn-remove",on:{"click":function($event){return _vm.handleBtnRemove(scope.row.name, scope.row.templateId, scope.row.zq)}}},[_c('i',{staticClass:"el-icon-delete"})])]:(scope.row.status === '待修改')?[_c('router-link',{staticClass:"btn btn-edit",attrs:{"to":{
                  name: _vm.manualTableRouteName,
                  params: {
                    templateID: scope.row.templateId,
                    from: _vm.manner,
                    productID: _vm.product.id ? _vm.product.id : 191,
                    productName: _vm.product.name || '无',
                    zqLocation: _vm.zqLocation,
                  },
                  query: {
                    tableName: scope.row.name,
                    zq: scope.row.zq,
                    productID: _vm.product.id,
                    productName: _vm.product.name,
                    bankID: _vm.product.bankID,

                    uuid: _vm.uuid,
                    templateID: scope.row.templateId,
                    companyName: _vm.$route.query.companyName,
                    taxNo: _vm.$route.query.taxNo,
                    nsrsbh: _vm.$route.query.nsrsbh,
                    fromHouTai: _vm.$route.query.fromHouTai,
                  },
                }}},[_c('i',{staticClass:"el-icon-edit-outline"})]),_c('button',{staticClass:"btn btn-remove",on:{"click":function($event){return _vm.handleBtnRemove(scope.row.name, scope.row.templateId, scope.row.zq)}}},[_c('i',{staticClass:"el-icon-delete"})])]:[(scope.row.name.indexOf('余额表') == -1)?_c('router-link',{staticClass:"btn btn-view-edit",attrs:{"to":{
                  name: _vm.manualTableRouteName,
                  params: {
                    templateID: scope.row.templateId,
                    from: _vm.manner,
                    productID: _vm.product.id ? _vm.product.id : 191,
                    productName: _vm.product.name || '无',
                    zqLocation: _vm.zqLocation,
                  },
                  query: {
                    tableName: scope.row.name,
                    zq: scope.row.zq,
                    productID: _vm.product.id,
                    productName: _vm.product.name,
                    bankID: _vm.product.bankID,
                    activeName: _vm.activeName,

                    uuid: _vm.uuid,
                    templateID: scope.row.templateId,
                    companyName: _vm.$route.query.companyName,
                    taxNo: _vm.$route.query.taxNo,
                    nsrsbh: _vm.$route.query.nsrsbh,
                    fromHouTai: _vm.$route.query.fromHouTai,
                  },
                }}},[_c('i',{staticClass:"el-icon-edit-outline"})]):_vm._e()]]:undefined}}],null,true)})],1):_vm._e()],1)}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }