<template>
  <div>
    <div v-loading="!loaded" style="min-height: 200px">
      <div class="toolbar">
        <el-form ref="form" :model="filter" :inline="true" class="section-toolbar">
          <el-form-item label="时间:">
            <el-date-picker
              v-model="filter.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="mini"
              style="width: 240px"
              value-format="yyyy-MM-dd"
              @change="loadCompanyAreaList"
            >
            </el-date-picker>
          </el-form-item>
          <el-tooltip class="item" effect="dark" content="表示不含税金额" placement="top">
            <i class="el-icon-warning tip"></i>
          </el-tooltip>
          <el-form-item label="金额（万元）:" style="margin-right: 12px">
            <el-input
              v-model="filter.fromAmount"
              size="mini"
              style="width: 88px"
              @change="loadCompanyAreaList"
            ></el-input>
          </el-form-item>
          <el-form-item label="至">
            <el-input
              v-model="filter.toAmount"
              size="mini"
              style="width: 88px"
              @change="loadCompanyAreaList"
            ></el-input>
          </el-form-item>
          <el-tooltip
            class="item"
            effect="dark"
            content="展示金额排名前N的上游企业和下游企业"
            placement="top"
          >
            <i class="el-icon-warning tip"></i>
          </el-tooltip>
          <el-form-item label="展示排名:">
            <BigPowerInput
              type="number"
              :min="1"
              v-model="filter.limit"
              size="mini"
              style="width: 60px"
              @change="loadCompanyAreaList"
            ></BigPowerInput>
          </el-form-item>
          <el-form-item label="">
            <el-checkbox
              label="只显示风险企业"
              name="type"
              v-model="filter.showCompanyRisk"
              @change="loadCompanyAreaList"
            ></el-checkbox>
            <!-- <el-tooltip class="item" effect="dark" content="风险企业指单笔交易金额5万及以上的风险企业，如需完整的风险企业数据可拨打客服热线： 0571-85055930" placement="right-start">
                           <i class="el-icon-warning-outline el-icon-warning-outline-dark"></i>
                         </el-tooltip> -->
            <el-tooltip class="item" effect="dark" placement="right-start">
              <div slot="content">
                下图中符合如下条件的企业会显示风险提示。 <br />
                1、自然年度内交易的价税合计金额排名前50(含)的上游企业和下游企业； <br />
                2、有风险的企业
              </div>
              <i class="el-icon-warning-outline el-icon-warning-outline-dark"></i>
            </el-tooltip>
          </el-form-item>
        </el-form>
        <button class="btn-link btn-fullscreen" @click="handleFullscreen">图谱全屏</button>
      </div>
      <div>
        <NetworkChart :data="data" ref="networkChart"></NetworkChart>
      </div>
    </div>
    <el-dialog
      :visible.sync="fullScreenDialogVisible"
      :fullscreen="true"
      :modal="false"
      :title="''"
    >
      <NetworkChart :data="data" ref="networkChart2" height="100vh"></NetworkChart>
    </el-dialog>
  </div>
</template>

<script>
import NetworkChart from "@/pages/Result/ThreeYearResult/financialAnalysis/networkChart/NetworkChart";
import { getCompanyRiskImage } from "@/api/threeYears";

export default {
  name: "NetWorkChartWindow",
  components: {
    NetworkChart,
  },
  data() {
    return {
      loaded: false,
      data: null,
      fullScreenDialogVisible: false,
      filter: {
        time: [],
        fromAmount: "",
        toAmount: "",
        limit: 15,
        showCompanyRisk: false,
      },
    };
  },
  async mounted() {
    this.loaded = false;
    await this.loadCompanyAreaList();
    this.loaded = true;
  },
  methods: {
    handleFullscreen() {
      this.fullScreenDialogVisible = true;
      setTimeout(() => {
        this.$refs["networkChart2"].render();
      }, 500);
    },
    changeTime() {
      this.loadCompanyAreaList();
    },
    async loadCompanyAreaList() {
      const params = {};
      if (this.filter.time && this.filter.time[0]) {
        params.startDate = this.filter.time[0];
        params.endDate = this.filter.time[1];
      }

      params.programId = this.$route.params.uuid;
      params.startMoney = this.filter.fromAmount;
      params.endMoney = this.filter.toAmount;
      params.showRiskListSize = this.filter.limit;
      params.showCompanyRisk = this.filter.showCompanyRisk;
      params.desensitizationStatus = this.$route.query.desensitization;
      const res = await getCompanyRiskImage(params);
      this.data = res.data;
    },
  },
};
</script>

<style lang="stylus" scoped>
.toolbar
  margin-top 24px
  display flex
  justify-content space-between
  align-items center

  .btn-fullscreen
    font-size 14px

>>> .el-dialog__header
  border-bottom: 0 none;
  padding 0

.el-icon-warning-outline-dark {
  margin-left: 6px;
  cursor: pointer;
}
</style>
